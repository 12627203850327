<template>
  <div class="about">
    <h1>Trivial pour Geek</h1>
    <img alt="logo" src="../assets/atom.png">

    <h3>Il y a longtemps, dans un arrondissement pas si lointain....</h3>

    <div class="story">
      <p>Deux copains qui aimaient jouer. Ils écrivaient des questions, un peu pour le fun, un peu pour en faire un truc sympa un jour. Et le jeu resta dans un recoin de drive Google.</p>
      <p>Et puis un jour, une fille leur dit: hé j'ai besoin d'un projet! Vous avez toujours votre trivial de geek? Je veux coder ça! Même que ça pourra être cool!</p>
      <p> Alors elle coda. Et le quizz fut. Et ça sera cool!</p>
    </div>


  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.about {
  width: 700px;
  margin: auto;
}
.about img {
  margin: 30px auto;
  height: 80px;
  width: 80px;
}

h1 {
  font-family: Orbitron, Helvetica, Arial, sans-serif;
}

h3 {
  margin: 15px auto;
}

.story {
  background-color: rgba(229, 231, 233,0.5);
  padding: 20px;
  margin: 15px auto;
  text-align: left;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 2em;
  border-radius: 15px;
}

</style>
